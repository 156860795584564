import moment from "moment";
import { parseFilters } from "./Utilities";
import { differenceInCalendarDays } from "date-fns";

export const getFormatedContentForHistories = async ({ historyData }) => {
  const today = moment();
  let resultContent = `*ACC_TestUpdate ${today.format("DD.MM.YYYY.")}*\n`;
  resultContent += `Here is your weekly update on the results of the currently running tests.\n`;
  resultContent += `Summary:\n`;
  const formatValue = (value) => {
    return Number(value)?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const combinedTestMessages = await Promise.all(
    historyData?.map(async (data) => {
      const refetchData = data?.reportData?.refetchTemporaryReportBigQuery;
      const variantData = refetchData?.calculatedResults;
      const { totalTransactions } = refetchData?.totalOfResults;
      const daysCount = data?.daysCount;
      const platforms = parseFilters(data?.filters);
      let messages = "";
      messages += `💡\n`;
      if (!variantData?.length) {
        messages += `>Test has been running for *${daysCount}* days, you will receive an update for this next week.\n\n`;
      } else {
        //Generate messages for each variant comparison
        const variantMessages = await Promise.all(
          variantData?.map((variant) => {
            if (!variant?.audienceName) return; // Skip empty audienceName

            const controlDataLast = refetchData?.result?.find(
              (ref) =>
                ref.controlvariant === "Reference" ||
                ref.controlvariant === "reference"
            );

            const vDataLast = refetchData?.result?.find(
              (ref) => ref.controlvariant === variant.audienceName
            );
            const {
              changeInConversionRate,
              additionalTransactionsPerMonth,
              additionalRevenuePerYear,
            } = variant;
            const rpuChange =
              vDataLast?.calculatedAverageRevenuePerUser <
              controlDataLast?.calculatedAverageRevenuePerUser
                ? "Decrease"
                : "Increase";
            const aovChange =
              vDataLast?.calculatedAveragePurchaseRevenue <
              controlDataLast?.calculatedAveragePurchaseRevenue
                ? "Decrease"
                : "Increase";
            let variantMessage = `${variant?.audienceName}\n`;
            variantMessage += `>The ${variant?.audienceName} is performing better on: *${platforms}*\n`;
            variantMessage += `>RPU: ${rpuChange} from *€${formatValue(
              controlDataLast?.calculatedAverageRevenuePerUser
            )}* to *€${formatValue(
              vDataLast?.calculatedAverageRevenuePerUser
            )}*\n`;
            variantMessage += `>Sample size: *${totalTransactions}* transactions\n`;
            variantMessage += `>AOV: ${aovChange} from *€${formatValue(
              controlDataLast?.calculatedAveragePurchaseRevenue
            )}* to *€${formatValue(
              vDataLast?.calculatedAveragePurchaseRevenue
            )}*\n`;

            variantMessage += `>Change in CR: *${
              changeInConversionRate?.replace(/\d+% ?/g, "") < 0 ? "" : "+"
            }${changeInConversionRate}*\n`;

            if (additionalTransactionsPerMonth > 0) {
              variantMessage += `>💲\n`;
              variantMessage += `>Additional transactions per month, at current traffic: *+${formatValue(
                additionalTransactionsPerMonth
              )}* transactions\n`;
              variantMessage += `>Additional revenue per year, at current AOV: *€${formatValue(
                additionalRevenuePerYear
              )}*\n`;
            }
            // Add test duration message
            variantMessage += `>⏳\n`;
            variantMessage += `>The test has been running for: *${
              30 - daysCount
            }* days\n`;
            variantMessage += `>The test can be evaluated in: *${daysCount}* days\n`;
            return variantMessage;
          })
        );
        messages += variantMessages?.join("\n\n");
        messages += `\n\nIf you have any questions, ideas or comments, let me know and I’ll be happy to help!_
_Info/replies in the thread_"`;
      }
      return messages;
    })
  );
  resultContent += combinedTestMessages?.join("\n\n");
  return resultContent;
};
