import Highlight from "react-highlight";
import { Button } from "../../MiroComponents/Button/Button";
import "./TargetDefination.scss";
import Input from "../../MiroComponents/Input/Input";
import { useSearchParams } from "react-router-dom";

export default function TargetDefination({
  targetSelected,
  allowEdit = false,
  ...props
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="full-width default-border default-padding radius-4">
      <h2 className="default-margin-bottom">
        {props?.name}
        {props?.type && (
          <>
            : <Button className="smart-pill small active" text={props?.type} />
          </>
        )}
      </h2>
      {targetSelected === "audience" && (
        <div className="default-border bg-2 full-width">
          <Highlight innerHTML={false} className={"javascript"}>
            {props?.rules_js}
          </Highlight>
        </div>
      )}
      {targetSelected === "goals" && (
        <ul className="default-border default-padding bg-2">
          <li className="d-flex align-items-center default-column-gap">
            <div className="d-inline-flex flex-wrap align-items-center default-column-gap">
              <span> Counting Method : </span>
              <Button
                className={`smart-pill small default-margin-right active
                  `}
                text={props?.counting_method}
              />{" "}
            </div>
          </li>
        </ul>
      )}
      {targetSelected === "page" && (
        <>
          <div className="default-padding bg-2 full-width default-margin-bottom">
            <h3> JS Rules</h3>
            <Highlight innerHTML={false} className={"javascript"}>
              {props?.rules_js}
            </Highlight>
          </div>
          {props?.urltargetings && props?.urltargetings.length > 0 && (
            <div className="default-padding bg-2">
              <h3> URL Targeting</h3>
              <ul className="default-row-gap d-flex flex-column">
                {props?.urltargetings.map((url, index) => (
                  <li key={index} className="default-padding-top">
                    <div className="d-inline-flex flex-wrap">
                      <div className="default-margin-bottom">
                        <span> Condition : </span>
                        <Button
                          className={`smart-pill small default-margin-right 
                    ${url?.type === "include" ? `active` : `notactive`}
                  `}
                          text={url?.type}
                        />{" "}
                      </div>
                      <div>
                        <span>Match Type : </span>
                        <Button
                          className="smart-pill small active"
                          text={url?.url_type}
                        />
                      </div>
                    </div>
                    <div className="default-margin-top">
                      URL :{" "}
                      <span
                        className={url?.type === "include" ? `neon` : `red`}
                      >
                        {url?.url}{" "}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {allowEdit && (
        <div className="d-flex justify-end default-margin-top">
          <Button
            className="smart-button small"
            text="Edit"
            onClick={() => {
              setSearchParams({
                ...searchParams,
                target: targetSelected,
                id: props?._id,
              });
            }}
          />
        </div>
      )}

      {/* {props?.rules_js && (
        <div className="full-width">
          <Input
            label="Rules"
            value={props?.rules_js}
            wrapperClass="full-width margin-bottom-auto"
            inputWrapperClass="full-width small"
          />
        </div>
      )} */}
      {/* <p className="">
        Check for data in live reporting module after 24 hours of running an
        experiment.
      </p> */}
    </div>
  );
}
