import create from "zustand";
const useAbTestStore = create((set, get) => ({
  insights: {
    experiments: [],
    pages: [],
    audiences: [],
    goals: [],
    experimentsCount: 0,
    pagesCount: 0,
    goalsCount: 0,
    audiencesCount: 0,
    loading: false,
    forceRender: Math.random(),
  },
  setTests: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        experiments: data?.tests,
        experimentsCount: data?.total,
        refetchTest: data?.refetchTests,
      },
    }));
  },
  updateTest: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        experiments: data?._id
          ? state.insights.experiments.map((test) =>
              test._id === data._id ? data : test
            )
          : [...state.insights.experiments],
        forceRender: Math.random(),
      },
    }));

    if (!data) {
      set((state) => ({
        insights: {
          ...state.insights,
          forceRender: Math.random(),
        },
      }));
    }
  },
  setGoals: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        goals: data?.goals,
        goalsCount: data?.total,
      },
    }));
  },
  setAudiences: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        audiences: data?.audiences,
        audiencesCount: data?.total,
      },
    }));
  },
  setPages: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        pages: data?.pageData,
        pagesCount: data?.total,
      },
    }));
  },
  updatePages: (data) => {
    set((state) => ({
      insights: {
        ...state.insights,
        pages: data?._id
          ? state.insights.pages.map((page) =>
              page._id === data._id ? data : page
            )
          : [...state.insights.pages],
        forceRender: Math.random(),
      },
    }));

    if (!data) {
      set((state) => ({
        insights: {
          ...state.insights,
          forceRender: Math.random(),
        },
      }));
    }
  },

  clearInsights: () =>
    set({
      insights: {
        experiments: [],
        pages: [],
        audiences: [],
        experimentsCount: 0,
        pagesCount: 0,
        audiencesCount: 0,
        loading: true,
      },
    }),
}));
export default useAbTestStore;
