import React, { useMemo } from "react";
import useAbTestStore from "../../../AbTestStore";
import { Button } from "../../MiroComponents/Button/Button";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import PageTargetingForm from "./PageTargetingForm";
import TargetDefination from "../TargetDefination/TargetDefination";
import { useMutation } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

export default function PageTargetings() {
  const { insights, updatePages } = useAbTestStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const _params = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams]
  );
  const pageBoxRef = React.useRef(null);
  const PageBox = React.forwardRef(({ page }, ref) => {
    return (
      <section className={`big-margin-bottom`} ref={ref}>
        <TargetDefination targetSelected="page" {...page} allowEdit={true} />
      </section>
    );
  });

  const refreshPage = () => {
    updatePages();
    setSearchParams({});
  };

  return (
    <div>
      <PageTargetingForm
        editObject={insights?.pages?.find((item) => item?._id === _params?.id)}
        refreshPage={refreshPage}
      />
      <div className="d-flex justify-content-between default-margin-bottom">
        <h1 className="margin-bottom-auto">Pages </h1>
        <div>
          <Button className="smart-button small active">Add New Page</Button>
        </div>
      </div>
      {insights?.pages?.length === 0 && (
        <NoDataFound
          title="No Pages Found"
          description="Add new targetings pages to start testing."
        />
      )}
      {insights?.pages?.map((page, index) => (
        <PageBox page={page} ref={pageBoxRef} key={index} />
      ))}
    </div>
  );
}
